//small
@media(min-width: 360px) {
  .dropdown-menu.show {
    inset: 0px 14rem auto auto !important;
  }

  .modal-backdrop {
    display: none;
  }

  .modal {
    background: rgba(0, 0, 0, 0.5);
  }

  .profile-username {
    font-size: 26px !important;
  }

  .profile-usermail {
    font-size: 14px !important;
  }

  .date-size {
    font-size: 14px;
  }

  .mat-step-label {
    display: none !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-selected {
    display: none !important;
  }

  .mat-step-label-selected,
  .mat-step-label {
    font-size: 15px !important;
  }

  .setupsmart .verLine {
    height: 40px !important;
  }

  .tick-style {
    width: 180px !important;
    height: 180px !important;
  }

  .footer {
    width: 50% !important;
  }
}

//Galaxy fold
@media (min-width: 280px) and (max-width: 653px) {
  .list-description {
    font-size: 12px !important;
  }

  .footer {
    width: 50% !important;
  }

  .dropdown-menu.show {
    inset: 0px 13rem auto auto !important;
  }

  .modal-backdrop {
    display: none;
  }

  .modal {
    background: rgba(0, 0, 0, 0.5);
  }

  .profile-username {
    font-size: 26px !important;
  }

  .profile-usermail {
    font-size: 14px !important;
  }

  .date-size {
    font-size: 14px;
  }

  .mat-step-label {
    display: none !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-selected {
    display: none !important;
  }

  .mat-step-label-selected,
  .mat-step-label {
    font-size: 15px !important;
  }

  .setupsmart .verLine {
    height: 40px !important;
  }
}

// iPhone 6/7/8 plus
@media(min-width: 414px) {
  .footer {
    width: 50% !important;
  }

  .dropdown-menu.show {
    inset: 0px 14rem auto auto !important;
  }

  .modal-content {
    background-color: #fff;
    z-index: 1030;
  }

  .modal-backdrop {
    display: none;
  }

  .modal {
    background: rgba(0, 0, 0, 0.5);
  }

  .profile-username {
    font-size: 26px !important;
  }

  .profile-usermail {
    font-size: 14px !important;
  }

  .date-size {
    font-size: 14px;
  }

  .mat-step-label {
    display: none !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-selected {
    display: none !important;
  }

  .mat-step-label-selected,
  .mat-step-label {
    font-size: 15px !important;
  }

  .setupsmart .smartDesc .bulletpointdiv {
    height: 50px !important;
  }

}

//iPad
@media(min-width: 768px) {}

//iPad Pro
@media(min-width: 1024px) {}

//Desktop
@media(min-width: 1200px) {
  .footer {
      width: 10% !important;
    }
  .profile-username {
    font-size: 24px !important;
  }

  .profile-usermail {
    font-size: 14px !important;
  }

  .mat-step-label {
    display: block !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-selected {
    display: block !important;
  }

  .mat-step-label-selected,
  .mat-step-label {
    font-size: 18px !important;
  }

  .setupsmart .verLine {
    height: 53px !important;
  }

  .tick-style {
    width: 360px !important;
    height: 360px !important;
  }

  .setupsmart .smartDesc .bulletpointdiv {
    height: 20px !important;
  }
}

//Larger Desktop
@media(min-width: 1400px) {}